import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { API_CALL } from "./constant";
import '../src/logins.css';
import { useAuth } from "./authContext";



const Login = ({ onLogin }) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rsesponsee, setResponsee] = useState("");
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_CALL}login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, password })
            });

            if (response.ok == true) {
                console.log('response');
                console.log(response);

                const data = await response.json();
                const token = data.token;
                localStorage.setItem('token', token);

                console.log(localStorage.getItem('token', token));

                //sessionStorage.setItem('justLoggedIn', 'true');
                localStorage.setItem('justLoggedIn', 'true');
                console.log(localStorage.getItem('justLoggedIn', 'true'));

                login();
                navigate('/dashboard');


            } else {
                const data = await response.json();
                setResponsee(data.error);
                console.log(response);
            }
        } catch (error) {
            console.log("Error logging in:", error);
        }
    };
    return (
        <div className="login-container">
            <form className="Loginform" onSubmit={handleSubmit}>
                <h1>Welcome!!</h1>
                <br />
                <h3>Login Here</h3>
                <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your Email Id"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Your Password"
                />
                <br />
                <button class="button1" type="submit">Submit</button>
                {rsesponsee && <p style={{ color: 'red' }}>{rsesponsee}</p>}
            </form>
        </div>

    )
};

export default Login;
