import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API_CALL } from './constant';

const DatabaseLogsPage = () => {
    const { clientId, date } = useParams();
    const [databaseLogs, setDatabaseLogs] = useState([]);
    
    useEffect(() => {
        const fetchDatabaseLogs = async () => {
            try {
                const formattedDate = formatDate(date);
                const response = await fetch(`${API_CALL}getDatabaseLogs/${clientId}/${formattedDate}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch database logs');
                }
                const logs = await response.json();
                setDatabaseLogs(logs);
            } catch (error) {
                console.error('Error fetching database logs:', error);
                alert('Failed to fetch database logs.');
            }
        };

        fetchDatabaseLogs();
    }, [clientId, date]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0'); 
        return `${year}-${month}-${day}`;
    };

    return (
        <div>
            <h2>Database Logs</h2>
            <table>
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Client Id</th>
                        <th>List Id</th>
                        <th>Date</th>
                        <th>Status</th>
                        

                    </tr>
                </thead>
                <tbody>
                    {databaseLogs.map((log, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{log.client_id}</td>
                            <td>{log.list_id}</td>
                            <td>{log.date}</td>
                            <td>Completed</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default DatabaseLogsPage;
