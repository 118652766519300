// AuthContext.js
import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('token') !== null
  );

  const login = () => {
    // Your login logic here (e.g., set isAuthenticated to true)
    setIsAuthenticated(true);
  };

  const logout = () => {
    // Your logout logic here (e.g., set isAuthenticated to false)
    localStorage.removeItem('token'); // Remove token from local storage
    setIsAuthenticated(false);
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
export const useAuth = () => useContext(AuthContext);
