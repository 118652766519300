import React, { useState, useEffect } from 'react';
import { API_CALL } from "./constant";
import "../src/dashboard.css";
import { useAuth } from "./authContext";
import { useNavigate } from "react-router-dom";
import '../src/master.css';
import Select from 'react-select';

const MasterSuppression = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [listData, setListData] = useState([]);
    const [lists, setLists] = useState([]);
    const [segments, setSegments] = useState([]);
    const [formData, setFormData] = useState({
        clientId: '',
        listId: '',
        segmentIds: '',
        masterSuppressionList: ''
    });
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await fetch(`${API_CALL}getSegmendDataMaster`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setListData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchLists = async (clientId) => {
        try {
            const response = await fetch('https://api.ongage.net/api/lists?type=sending', {
                headers: {
                    'X_USERNAME': 'wp562235',
                    'X_PASSWORD': 'J*n+swun3-9Pr7b-viWr',
                    'X_ACCOUNT_CODE': 'lop_solutions'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch lists');
            }
            const data = await response.json();
            setLists(data.payload);
        } catch (error) {
            console.error('Error fetching lists:', error);
        }
    };

    const fetchSegments = async (listId) => {
        try {
            const response = await fetch(`https://api.ongage.net/${listId}/api/segments`, {
                headers: {
                    'X_USERNAME': 'wp562235',
                    'X_PASSWORD': 'J*n+swun3-9Pr7b-viWr',
                    'X_ACCOUNT_CODE': 'lop_solutions'
                }
            });
            if (!response.ok) {
                throw new Error('Failed to fetch segments');
            }
            const data = await response.json();
            setSegments(data.payload);
        } catch (error) {
            console.error('Error fetching segments:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'clientId') {
            fetchLists(value);
        } else if (name === 'listId') {
            fetchSegments(value);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (formData.segmentIds && typeof formData.segmentIds === 'string' && formData.segmentIds.trim() !== '') {
                const segmentIdsArray = formData.segmentIds.split(',').map(id => parseInt(id.trim()));
                setFormData(prevState => ({
                    ...prevState,
                    segmentIds: segmentIdsArray
                }));
            }

            const response = await fetch(`${API_CALL}addSegmendDataMaster`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
            if (!response.ok) {
                throw new Error('Failed to add data');
            }
            fetchData();
            setFormData({
                clientId: '',
                listId: '',
                segmentIds: '',
                masterSuppressionList: ''
            });
        } catch (error) {
            console.error('Error adding data:', error);
        }
    };

    const handleRemove = async (clientId, listId) => {
        try {
            const response = await fetch(`${API_CALL}removeSegmendDataMaster`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clientId, listId })
            });
            if (!response.ok) {
                throw new Error('Failed to remove data');
            }
            fetchData();
        } catch (error) {
            console.error('Error removing data:', error);
        }
    };

    const handleMasterSuppressionNavigation = () => {
        navigate('/masterSuppression');
    };

    const handleMeganSuppressionNavigation = () => {
        navigate('/dashboard');
    };

    const handleLogout = () => {
        logout();
    };

    const handleStatusChange = async (clientId, listId, newStatus) => {
        try {
            const response = await fetch(`${API_CALL}updateStatus`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ clientId, listId, status: newStatus })
            });
            if (!response.ok) {
                throw new Error('Failed to update status');
            }
            fetchData();
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleUpdate = (item) => {
        setSelectedItem(item);
        setIsUpdating(true);
    };

    const handleUpdateCancel = () => {
        setSelectedItem(null);
        setIsUpdating(false);
    };

    const handleUpdateSubmit = async (updatedData) => {
        try {
            const response = await fetch(`${API_CALL}updateSegmendDataMaster`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedData)
            });
            if (!response.ok) {
                throw new Error('Failed to update data');
            }
            fetchData(); // Fetch the updated data after successful update
            setIsUpdating(false); // Reset updating state
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleUpdateFieldChange = (index, fieldName, value) => {
        const updatedListData = [...listData];
        updatedListData[index][fieldName] = value;
        setListData(updatedListData);
    };

    return (
        <div>
            <div className="dashboard-header">
                <div className="navigation-header">
                    <button className="dashboard-button" onClick={handleMeganSuppressionNavigation}>Megan Suppression</button>
                    <button className="dashboard-button" onClick={handleMasterSuppressionNavigation}>Master Suppression</button>
                </div>
                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
            <h1>User Dashboard For Master Suppression</h1>
            <div className="form-box">
                <h3>Add Data To Database</h3>
                <form onSubmit={handleSubmit} className="form-container">
                    <div className="form-group">
                        <label htmlFor="clientId" className="form-label">Client ID:</label>
                        <input type="text" id="clientId" name="clientId" value={formData.clientId} onChange={handleInputChange} className="form-input" />
                    </div>
                    <div className="form-group">
                        <label htmlFor="listId" className="form-label">List ID:</label>
                        <select id="listId" name="listId" value={formData.listId} onChange={handleInputChange} className="form-input">
                            <option value="">Select List</option>
                            {lists.map(list => (
                                <option key={list.id} value={list.id}>{`${list.name} (${list.id})`}</option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="form-group">
                        <label htmlFor="segmentIds" className="form-label">Segment ID:</label>
                        <select id="segmentIds" name="segmentIds" value={formData.segmentIds} onChange={handleInputChange} className="form-input">
                            <option value="">Select Segment</option>
                            {segments.map(segment => (
                                <option key={segment.id} value={segment.id}>{`${segment.name} (${segment.id})`}</option>
                            ))}
                        </select>
                    </div> */}
                    <div className="form-group">
                        <label htmlFor="segmentIds" className="form-label">Segment IDs:</label>

                        <Select
                            id="segmentIds"
                            name="segmentIds"
                            //  value={formData.segmentIds.map(id => ({ value: id.toString(), label: id.toString() }))}
                            onChange={(selectedOptions) => {
                                const selectedIds = selectedOptions.map(option => parseInt(option.value, 10));
                                setFormData(prevState => ({
                                    ...prevState,
                                    segmentIds: selectedIds
                                }));
                            }}
                            options={segments.map(segment => ({ value: segment.id.toString(), label: `${segment.name} (${segment.id})` }))}
                            isMulti
                        />

                    </div>
                    <div className="form-group">
                        <label htmlFor="masterSuppressionList" className="form-label">Master Suppression List:</label>
                        <input type="text" id="masterSuppressionList" name="masterSuppressionList" value={formData.masterSuppressionList} onChange={handleInputChange} className="form-input" />
                    </div>
                    <button type="submit" className="btn btn-primary">Add Data</button>
                </form>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Client ID</th>
                        <th>List ID</th>
                        <th>Segment ID</th>
                        <th>Master Suppression List</th>
                        <th>Status</th>
                        <th>Remove</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {listData.map((item, index) => (
                        <tr key={`${item.clientId}-${item.listId}`}>
                            <td>
                                {isUpdating && selectedItem === item ? (
                                    <input
                                        type="text"
                                        value={item.clientId}
                                        onChange={(e) => handleUpdateFieldChange(index, 'clientId', e.target.value)}
                                    />
                                ) : (
                                    item.clientId
                                )}
                            </td>
                            <td>
                                {isUpdating && selectedItem === item ? (
                                    <select
                                        value={item.listId}
                                        onChange={(e) => handleUpdateFieldChange(index, 'listId', e.target.value)}
                                    >
                                        {lists.map(list => (
                                            <option key={list.id} value={list.id}>{`${list.name} (${list.id})`}</option>
                                        ))}
                                    </select>
                                ) : (
                                    `${lists.find(list => list.id === item.listId)?.name} (${item.listId})`
                                )}
                            </td>
                            {/* <td>
                                {isUpdating && selectedItem === item ? (
                                    <select
                                        value={item.segmentIds}
                                        onChange={(e) => handleUpdateFieldChange(index, 'segmentIds', e.target.value)}
                                    >
                                        {segments.map(segment => (
                                            <option key={segment.id} value={segment.id}>{`${segment.name} (${segment.id})`}</option>
                                        ))}
                                    </select>
                                ) : (
                                    `${segments.find(segment => segment.id === item.segmentIds)?.name} (${item.segmentIds})`
                                )}
                            </td> */}
                            <td>
                                {isUpdating && selectedItem === item ? (
                                    <select
                                        value={item.segmentIds}
                                        onChange={(e) => handleUpdateFieldChange(index, 'segmentIds', Array.from(e.target.selectedOptions, option => option.value))}
                                        //multiple // Enable multiple selection
                                    >
                                        {segments.map(segment => (
                                            <option key={segment.id} value={segment.id}>{`${segment.name} (${segment.id})`}</option>
                                        ))}
                                    </select>
                                    // <Select
                                    //     id="segmentIds"
                                    //     name="segmentIds"
                                    //     //  value={formData.segmentIds.map(id => ({ value: id.toString(), label: id.toString() }))}
                                    //     onChange={(selectedOptions) => {
                                    //         const selectedIds = selectedOptions.map(option => parseInt(option.value, 10));
                                    //         setFormData(prevState => ({
                                    //             ...prevState,
                                    //             segmentIds: selectedIds
                                    //         }));
                                    //     }}
                                    //     options={segments.map(segment => ({ value: segment.id.toString(), label: `${segment.name} (${segment.id})` }))}
                                    //     isMulti
                                    // />
                                ) : (
                                    <span>
                                        {Array.isArray(item.segmentIds) ?
                                            item.segmentIds.map(id => `${segments.find(segment => segment.id === id)?.name} (${id})`).join(', ')
                                            :
                                            item.segmentIds // Show the segment IDs as is if it's not an array
                                        }
                                    </span>
                                )}
                            </td>


                            <td>
                                {isUpdating && selectedItem === item ? (
                                    <input
                                        type="text"
                                        value={item.masterSuppressionList}
                                        onChange={(e) => handleUpdateFieldChange(index, 'masterSuppressionList', e.target.value)}
                                    />
                                ) : (
                                    item.masterSuppressionList
                                )}
                            </td>
                            <td>
                                <select
                                    value={item.status}
                                    onChange={(e) => handleStatusChange(item.clientId, item.listId, e.target.value)}
                                >
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </td>
                            <td>
                                <button onClick={() => handleRemove(item.clientId, item.listId)}>Remove</button>
                            </td>
                            <td>
                                {isUpdating && selectedItem === item ? (
                                    <button onClick={() => handleUpdateSubmit(item)}>Submit</button>
                                ) : (
                                    <button onClick={() => handleUpdate(item)}>Update</button>
                                )}
                                {isUpdating && selectedItem === item && (
                                    <button onClick={handleUpdateCancel}>Cancel</button>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MasterSuppression;