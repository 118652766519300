import React, { useState, useEffect } from 'react';
import { API_CALL, DOWNLOAD_CALL } from "./constant";
import "../src/dashboard.css";
import { useAuth } from "./authContext";
import { Link, Navigate } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

const Dashboard = () => {

    const { logout } = useAuth();
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate();
    // const [databaseLogs, setDatabaseLogs] = useState([]);
    // const [showDatabaseLogs, setShowDatabaseLogs] = useState(false);

    const fetchData = async () => {
        try {
            const response = await fetch(`${API_CALL}getusers`);
            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            setUserData(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleMasterSuppressionNavigation = () => {
        navigate('/masterSuppression');
    };

    const handleDownloadMergeFile = async (clientId, fileName, datestr) => {
        try {
            const date = new Date(datestr);

            const formattedDate = date.toISOString().split('T')[0];
            // http://megan.lopsolutions.com/api/download/Megan/5516/2024-05-27/Megan_Export_20240527_063209.zip
            const url = `${DOWNLOAD_CALL}/${clientId}/${formattedDate}/${fileName}`;

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error('Failed to download merge file');
            }

            const blob = await response.blob();

            const a = document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = fileName;
            a.click();
        } catch (error) {
            console.error('Error downloading merge file:', error);
            alert('The file is not available for download.');
        }
    };
    // const handleDownloadDatabaseLogs = async (clientId, date) => {
    //     try {
    //         // Format the date string to match the required format (e.g., "2024-04-25")
    //         const formattedDate = formatDate(date); // Implement formatDate function

    //         // Make the fetch request with the formatted date
    //         const response = await fetch(`${API_CALL}getDatabaseLogs/${clientId}/${formattedDate}`);
    //         if (!response.ok) {
    //             throw new Error('Failed to fetch database logs');
    //         }
    //         const logs = await response.json();
    //         setDatabaseLogs(logs);
    //         setShowDatabaseLogs(true);
    //     } catch (error) {
    //         console.error('Error fetching database logs:', error);
    //         alert('Failed to fetch database logs.');
    //     }
    // };

    // Function to format date string to "YYYY-MM-DD" format
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero if needed
        return `${year}-${month}-${day}`;
    };


    const handleLogout = () => {
        logout();
    };


    return (
        <div>
            <div className="dashboard-header">
                <div className="navigation-header">
                    <button className="dashboard-button" >Megan Suppression</button>
                    <button className="dashboard-button" onClick={handleMasterSuppressionNavigation}>Master Suppression</button>
                </div>

                <button className="logout-button" onClick={handleLogout}>Logout</button>
            </div>
            <h1>User Dashboard For Megan Suppression</h1>
            <table>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>Client Id</th>
                        <th>Date</th>
                        <th>File Name</th>
                        <th>Download Merge File</th>
                        <th>Download Zip</th>
                        <th>Download Database Logs</th>
                    </tr>
                </thead>
                <tbody>
                    {userData.map((user, index) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.client_id}</td>
                            <td>{new Date(user.date).toLocaleDateString()}</td>
                            <td>{user.file_name}</td>
                            <td>
                                <button className="button" onClick={() => handleDownloadMergeFile(user.client_id, user.file_name, user.date)}>
                                    Download Merge File
                                </button>
                            </td>

                            <td>
                                <button className="button" onClick={() => handleDownloadMergeFile(user.client_id, user.zip_file, user.date)}>
                                    Download Zip
                                </button>
                            </td>

                            {/* <td>
                                <button className="button" onClick={() => handleDownloadDatabaseLogs(user.client_id, user.date)}>
                                    Download Database Logs
                                </button>
                            </td> */}
                            <td>
                                <Link to={`/database-logs/${user.client_id}/${formatDate(user.date)}`} className="button">
                                    Database Logs
                                </Link>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </table>

        </div>
    );
};

export default Dashboard;

